import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import { Row, Col } from 'reactstrap';
import Layout from 'components/Layout';
import SectionsWrapper from 'components/SectionsWrapper';
import { useLayoutContext } from 'context/LayoutContext';
import DocumentList from 'components/DocumentList';
import Section from 'components/Section';
import Headline from 'components/Headline';
import SEO from 'components/SEO';

const policies = [
  { name: 'Apex Customer Information Brochure', href: 'https://assets.tastyworks.com/production/documents/clearing_customer_information_brochure.pdf' },
  { name: 'Business Continuity Statement', href: 'https://assets.tastyworks.com/production/documents/business_continuity_statement.pdf' },
  { name: 'Customer Relationship Summary', href: 'https://assets.tastyworks.com/production/documents/customer_relationship_summary.pdf' },
  { name: 'tastytrade Privacy Policy', href: 'https://assets.tastyworks.com/production/documents/broker_privacy_policy.pdf' }
]
const futuresDisclosures = [
  { name: 'CFTC Rule 1.55 Risk Disclosure Statement', href: 'https://assets.tastyworks.com/production/documents/cftc_rule_1_55_risk_disclosure_statement.pdf' },
  { name: 'Futures & Exchange-Traded Options Risk Disclosure Statement', href: 'https://assets.tastyworks.com/production/documents/futures_exchange_traded_options_risk_disclosure_agreement.pdf' },
  { name: 'Futures On Virtual Currencies NFA Investor Advisory', href: 'https://assets.tastyworks.com/production/documents/futures_on_virtual_currencies_nfa_investor_advisory.pdf' },
  { name: 'Futures Options Disclosure Statement', href: 'https://assets.tastyworks.com/production/documents/futures_options_disclosure_statement.pdf' }
]
const securitiesDisclosures = [
  { name: 'Characteristics and Risks of Standardized Options', href: 'https://assets.tastyworks.com/production/documents/characteristics_and_risks_of_standardized_options.pdf' },
  { name: 'Day Trading Risk Disclosure', href: 'https://assets.tastyworks.com/production/documents/day_trading_risk_disclosure.pdf' },
  { name: 'Electronic Routing and Trading Systems Disclosure', href: 'https://assets.tastyworks.com/production/documents/electronic_routing_and_trading_risk_disclosures.pdf' },
  { name: 'Extended Hours Trading Risk Disclosure', href: 'https://assets.tastyworks.com/production/documents/extended_hours_trading_risk_disclosure.pdf' },
  { name: 'Margin Disclosure', href: 'https://assets.tastyworks.com/production/documents/margin_disclosure.pdf' },
  { name: 'Order Routing Disclosure Report', href: 'https://assets.tastyworks.com/production/documents/sec_rule_606_report.pdf' },
  { name: 'Portfolio Margin Risk Disclosure Statement', href: 'https://assets.tastyworks.com/production/documents/portfolio_margin_risk_disclosure_statement.pdf' },
  { name: 'Special Statement for Uncovered Options Writers', href: 'https://assets.tastyworks.com/production/documents/special_statement_for_uncovered_options_writers.pdf' }
]

const DisclosuresWrapper = styled(SectionsWrapper)`
  margin-top: 6.5625rem;
  padding-bottom: 2rem;

  ${ifProp('$showAlert', css`
    margin-top: 9.5625rem;
  `)}

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin-top: 10.0625rem;

    ${ifProp('$showAlert', css`
      margin-top: 13.0625rem;
    `)}
  }
`;

const HeadlineSection = styled(Section)`
  ${Headline} {
    padding-bottom: 2.5rem;
    text-align: left;
  }
`;

const StyledDiv = styled.div`
  width: 100%;

  h4 {
    border-bottom: 0.0625rem solid ${prop('theme.colors.lighterGray')};
    color: ${prop('theme.colors.gray')};
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;

    @media (min-width: ${prop('theme.breakpoints.md')}) {
      font-size: 1.5rem;
    }
  }
`;

function Disclosures() {
  const { state: layoutContextState } = useLayoutContext();
  const { showAlert } = layoutContextState;

  return (
    <Layout>
      <SEO title='Disclosures' canonicalHref='https://tastytrade.com/disclosures' />
      <DisclosuresWrapper $showAlert={showAlert} noGutters>
        <HeadlineSection withPadding>
          <Headline>
            Disclosures
          </Headline>
        </HeadlineSection>
        <Section withPadding>
          <Row>
            <Col sm={12} md={6}>
              <StyledDiv>
                <h4>Policies</h4>
                <DocumentList>
                  {policies.map(policy => {
                    return (
                      <li key={`policy_${policy.name}`}>
                        <a
                          href={policy.href}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {policy.name}
                        </a>
                      </li>
                    )
                  })}
                </DocumentList>
              </StyledDiv>
              <StyledDiv>
                <h4>Futures Disclosures</h4>
                <DocumentList>
                  {futuresDisclosures.map(form => {
                    return (
                      <li key={`form_${form.name}`}>
                        <a
                          href={form.href}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {form.name}
                        </a>
                      </li>
                    )
                  })}
                </DocumentList>
              </StyledDiv>
            </Col>
            <Col sm={12} md={6}>
              <StyledDiv>
                <h4>Securities Disclosures</h4>
                <DocumentList>
                  {securitiesDisclosures.map(form => {
                    return (
                      <li key={`form_${form.name}`}>
                        <a
                          href={form.href}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {form.name}
                        </a>
                      </li>
                    )
                  })}
                </DocumentList>
              </StyledDiv>
            </Col>
          </Row>
        </Section>
      </DisclosuresWrapper>
    </Layout>
  )
}

export default Disclosures;
